<template>
  <div class="mt-10">
    <h1 class="section__headline">Carta</h1>
    <!--begin::Carta-->
    <v-container>
      <v-row class="justify-content-center">
        <div class="col-xxl-8 order-2 order-xxl-1">
          <v-card flat class="card card-custom card-stretch gutter-b">
            <v-card-title class="font-weight-bolder text-dark table__header">
              <div>Listado de productos</div>
            </v-card-title>
            <div class="card-body pt-0 pb-3">
              <div>
                <v-text-field color="#FF8593" label="Filtro" required @keyup="applyFilter"></v-text-field>
              </div>
              <div class="text-right">
                <router-link :to="`/productPage/`">
                  <v-btn class="boton font-14 px-9 py-4 my-3">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    CREAR PRODUCTO
                  </v-btn>
                </router-link>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <v-row>
        <div class="col-xxl-12 order-2 order-xxl-1">
          <ProductList :productList="filteredList" :isFetching="isFetching" />
        </div>
      </v-row>
    </v-container>
    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      <div class="snackbar">
        {{ snackbar.text }}
        <v-btn color="black" text @click="snackbar.show = false"> Close </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { GET_PRODUCTS_BY_OWNER_ID } from '@/core/services/store/products.module';
import ProductList from '@/components/carta/ProductList';
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";

export default {
  name: 'Pedidos',
  components: {
    ProductList,
  },
  data() {
    return {
      filteredList: this.products,
      modalProduct: false,
      clickedProduct: 0,
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  watch: {
    products: function (newValue, oldValue) {
      this.filteredList = this.products;
      if (newValue.length < oldValue.length) {
        this.showSnackbar('Elemento eliminado con éxito')
      }
    },
  },
  mounted() {
    console.log('parametro');
    console.log(this.$route.query.status);
    if (this.$route.query.status)
      this.showSnackbar('Producto actualizado con éxito!!  👏 👏 👏');
    this.$store.dispatch(
      GET_PRODUCTS_BY_OWNER_ID,
      this.$store.state.auth.user.seller._id
    );
  },
  computed: {
    ...mapState({
      products: (state) => state.products.items,
      isFetching: (state) => state.products.isFetching,
    }),
  },
  methods: {
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    applyFilter(e) {
      if (e.target.value.length === 0) {
        this.filteredList = this.products;
      } else {
        this.filteredList = this.products.filter((item) => {
          return item.name.toUpperCase().includes(e.target.value.toUpperCase());
          // item.name.toUpperCase().contains(e.target.value)
        });
      }
    },
    openProduct(productId) {
      //Localizo el index del producto, y envío a la ventana de dialogo dicho producto por su index
      this.clickedProduct = this.products.findIndex((product) => {
        return product._id === productId;
      });
      this.modalProduct = true;
      // this.$socket.emit('test', 'JGil')
      // console.log(productId)
    },
  },
};
</script>
<style>
.timeline-item {
  min-height: 28px !important;
}
</style>
