<template>
  <div>
    <div class="product-list" v-if="!isFetching">
      <ProductCard v-for="element in productList" :key="element?._id" :product="element" @productDeleted="hey" />
    </div>

    <v-card v-else flat min-height="400px" class="text-center">
      <h1 class="mt-4">Cargando productos ...</h1>
      <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin:0 auto;" />
    </v-card>
    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      <div class="snackbar">
        {{ snackbar.text }}
        <v-btn color="black" text @click="snackbar.show = false"> Close </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import ProductCard from "@/components/carta/ProductCard";
export default {
  name: "ProductList",
  components: {
    ProductCard,
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  props: {
    productList: {
      type: Array,
      required: false,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
    hey(msg) {

      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
  },
};
</script>

<style scoped>
.product-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .product-list {
    justify-content: center;
  }
}
</style>
