<template>
  <v-hover v-slot:default="{ hover }">
    <v-card v-if="product" :elevation="hover ? 24 : 6" class="card-width col-lg-4 col-md-6 my-2">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="product__title">{{
    product?.name
}} </v-list-item-title>
          <v-list-item-subtitle class="subtitle-1">{{ product?.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="100" class="d-none d-md-flex">
          <v-img :src="getProductPic(product?.pic)" aspect-ratio="2" />
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions>
        <v-btn text color="info" class="d-none md-flex">Alergenos</v-btn>
        <v-tooltip v-for="allergen in product?.allergens" bottom color="success" :key="allergen._id">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">{{ allergen.icon }}</v-icon>
          </template>
          <span>{{ allergen.name }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <router-link :to="`/productPage/${product?._id} `">
          <v-btn icon>
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </router-link>
        <v-btn icon class="mr-2" @click="processDelete(product?._id)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <h3 class="no-margin">{{ product?.price }}€</h3>
      </v-card-actions>
    </v-card>
  </v-hover>

</template>

<script>
import { DELETE_PRODUCT } from "@/core/services/store/products.module";
import { GET_PRODUCTS_BY_OWNER_ID } from '@/core/services/store/products.module';
export default {
  name: "ProductCard",
  // props:{
  //     product:{
  //         type:Object,
  //         required:true
  //     }
  // },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  methods: {

    getProductPic(prodPic) {
      return prodPic === ""
        ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaO_1mPXorbpKRJRelbQRLKESJg7UZlEMs72nuJbD1XeUZdEy_&s"
        : prodPic;
    },
    processDelete(key) {
      this.$confirm("Está a punto de Eliminar un producto. ¿Está seguro?", {
        color: "#2196f3",
        title: "Confirmar acción",
        buttonTrueText: "Enviar",
        buttonFalseText: "Cancelar",
      }).then((res) => {
        if (res) {
          this.$store
            .dispatch(DELETE_PRODUCT, key)
            .then((message) => {
              // Idea ade refactor. La tarjeta no debería elimnar el elemento en si misma
              // debería mandar un evento y el superior tomarlo y ejecutar. De este modo, la tarjeta no puede emitir después de 
              // haber sido eliminada porque no existirá. Por eso nos hemos llevado todo lo relativo
              // a la ventana de notificación de éxito a otra parte
              
            })
            .catch((message) => {
              // this.$emit("closeModal", true);
            })
        }
      });
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin-bottom: 0;
}

.product__title {
  font-size: 16px !important;
}

.card-width {
  max-width: 400px;
}

/* @media (min-width: 768px){
        .card-width{
            max-width: 500px;
        }
    } */

@media (min-width: 1264px) {
  .card-width {
    max-width: 360px;
  }
}
</style>
